import React, { useEffect, useState } from "react";
import { Loading } from "../loading";
import { getNearestVenue } from "../utils/getNearestVenue";

const venuesEndpoint = "https://api.foursquare.com/v2/venues/explore?";

export interface Venue {
  name?: string;
  id?: string;
  distance: number;
  location?: {
    formattedAddress: Array<string>;
    address: string;
    lat: string;
    lon: string;
  };
}

export interface NearestVenueProps {
  category: "drinkIn" | "takeaway";
}

interface VenueItem {
  venue: Venue;
}

export const NearestVenue = ({ category }: NearestVenueProps): JSX.Element => {
  const takeaway = category === "takeaway";

  const [loading, setLoading] = useState<boolean>(false);
  const [venue, setVenue] = useState<null | Venue>(null);

  const callback = (latlong: string) => {
    setLoading(true);
    const defaultParams = {
      client_id: process.env.REACT_APP_FOURSQUARE_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET || "",
      limit: "1",
      openNow: "1",
      sortByDistance: "1",
      v: "20130619",
      ll: latlong,
    };

    const drinkInParams = {
      ...defaultParams,
      section: "drinks",
    };

    const takeawayParams = {
      ...defaultParams,
      categoryId:
        "5370f356bcbc57f1066c94c2,4bf58dd8d48988d186941735,4bf58dd8d48988d119951735",
    };

    fetch(
      venuesEndpoint +
        new URLSearchParams(takeaway ? takeawayParams : drinkInParams),
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const venues = response.response.groups[0].items;

        venues.sort((a: VenueItem, b: VenueItem) => {
          const fa = a.venue.distance,
            fb = b.venue.distance;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });

        const venue: Venue = venues[0]?.venue || null;

        setLoading(false);
        if (!venue) {
          return;
        }

        console.log(venue);
        setVenue(venue);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getNearestVenue({ callback: callback });
  }, []);

  if (loading) {
    return <Loading />;
  }

  const fullAddress = venue?.location?.formattedAddress.join(", ");

  return (
    <div>
      {venue ? (
        <>
          <p style={{ marginTop: 0, fontSize: "30px" }}>{`${venue.name}`}</p>
          <a href={`maps:0,0?q=${venue.name}+${fullAddress}`}>{fullAddress}</a>
        </>
      ) : (
        <p>
          Doesn&apos;t look like anything&apos;s open right now. Check back
          later.
        </p>
      )}
    </div>
  );
};
