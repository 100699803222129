const getLocation = (callback: {
  (latlong: string): void;
  (arg0: string): void;
}) => {
  navigator.geolocation.getCurrentPosition((location) => {
    callback(location.coords.latitude + "," + location.coords.longitude);
  });
};

export const getNearestVenue = ({
  callback,
}: {
  callback: (latlong: string) => void;
}): void => {
  getLocation(callback);
};
