import React, { useEffect } from "react";

declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}

export const GoogleAd = (): JSX.Element => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-2138256327024996"
      data-ad-slot="2367770786"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
};
