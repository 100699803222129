import React from "react";
import "whatwg-fetch";
import "./App.css";
import { GoogleAd } from "./components/Ads/GoogleAd";
import { NearestVenue } from "./nearest-venue";

const App = (): JSX.Element => {
  return (
    <div className="App">
      <div className="App-header">
        <h1>Nearest Beer</h1>
      </div>
      <div className="App-content">
        <div className="Venue-wrapper">
          <h3 style={{ color: "#F5BB00" }}>Your nearest beer is</h3>
          <NearestVenue category={"drinkIn"} />
        </div>
        <div className="Venue-wrapper">
          <h3 style={{ color: "#F5BB00" }}>
            Or if you&apos;re looking for takeaways
          </h3>
          <NearestVenue category={"takeaway"} />
        </div>
      </div>
      <div className="Ad-wrapper">
        <GoogleAd />
      </div>
    </div>
  );
};

export default App;
